import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import isEmpty from 'redux-actions/lib/utils/isEmpty';

import injectT from '../../../i18n/injectT';
import WrappedText from '../../../shared/wrapped-text/WrappedText';
import ReservationInfo from '../reservation-info/ReservationInfo';
import Equipment from '../resource-equipment/ResourceEquipment';
import ResourcePanel from './ResourcePanel';

function ResourceInfo({
  isLoggedIn, resource, unit, t,
}) {
  const hasProducts = resource.products && resource.products.length > 0;
  const { accessibilitySummaries, accessibilityDescription } = resource;
  const accessibilitySummariesArray = accessibilitySummaries.map(accessibility => accessibility.viewpointName);
  let accessibilityDescriptionArray = [];

  if (accessibilityDescription) {
    const accessibilityDescriptions = accessibilityDescription.split('\r\n');
    accessibilityDescriptionArray = accessibilityDescriptions.map(accessibility => accessibility.trim());
  }
  const accessibilitySummariesAndDescription = [...accessibilitySummariesArray, ...accessibilityDescriptionArray];

  const getResourcePlacement = () => {
    const { placement } = resource;
    let resourcePlacementText = '';
    if (placement === 'inside') {
      resourcePlacementText = t('ResourceInfo.resourcePlacementInside');
    } else if (placement === 'outside') {
      resourcePlacementText = t('ResourceInfo.resourcePlacementOutside');
    } else if (placement === 'both') {
      resourcePlacementText = t('ResourceInfo.resourcePlacementBoth');
    }
    return `${t('ResourceInfo.resourcePlacementText')} ${resourcePlacementText}`;
  };

  return (
    <section className="app-ResourceInfo">
      {resource.description && (
        <ResourcePanel header={t('ResourceInfo.descriptionTitle')}>
          <div className="app-ResourceInfo__description">
            <WrappedText openLinksInNewTab text={resource.description} />
          </div>
        </ResourcePanel>
      )}

      {resource.placement && (
        <span className="app-ResourceInfo__placement">
          {getResourcePlacement()}
        </span>
      )}

      {resource.genericTerms && (
        <ResourcePanel defaultExpanded={false} header={t('ResourcePage.genericTermsHeader')}>
          <WrappedText text={resource.genericTerms} />
        </ResourcePanel>
      )}

      {resource.specificTerms && (
        <ResourcePanel header={t('ResourcePage.specificTerms')}>
          <WrappedText text={resource.specificTerms} />
        </ResourcePanel>
      )}

      {hasProducts && resource.paymentTerms && (
        <ResourcePanel defaultExpanded={false} header={t('paymentTerms.title')}>
          <WrappedText text={resource.paymentTerms} />
        </ResourcePanel>
      )}

      <ResourcePanel header={t('ResourceInfo.additionalInfoTitle')}>
        <Row>
          {unit && (
            <Col className="app-ResourceInfo__address" xs={6}>
              {unit.name && <span>{unit.name}</span>}
              {unit.streetAddress && <span>{unit.streetAddress}</span>}
              {unit.addressZip && <span>{`${unit.addressZip} ${upperFirst(unit.municipality || '')}`.trim()}</span>}
              {unit.phone && <span>{unit.phone}</span>}
            </Col>
          )}
          <Col className="app-ResourceInfo__web" xs={6}>
            {unit && unit.wwwUrl && (
              <span className="app-ResourceInfo__www">
                <a href={unit.wwwUrl} rel="noopener noreferrer" target="_blank">
                  {t('ResourceInfo.serviceWebLink')}
                </a>
              </span>
            )}
          </Col>
        </Row>
      </ResourcePanel>

      {resource.responsibleContactInfo && (
        <ResourcePanel header={t('ResourceInfo.responsibleContactInfoTitle')}>
          <Row>
            <Col className="app-ResourceInfo__responsibleContactInfo" xs={6}>
              {resource.responsibleContactInfo}
            </Col>
          </Row>
        </ResourcePanel>
      )}

      {!isEmpty(accessibilitySummariesAndDescription) && (
        <ResourcePanel header={t('ResourceAccessibility.headingText')}>
          <ul>
            {accessibilitySummariesAndDescription.map((accessibility, index) => (
              <li key={index}>{accessibility}</li>
            ))}
          </ul>
        </ResourcePanel>
      )}

      { Array.isArray(resource.equipment)
      && resource.equipment.length > 0 && (<Equipment equipment={resource.equipment} />) }

      <ResourcePanel header={t('ResourceInfo.reservationTitle')}>
        <ReservationInfo isLoggedIn={isLoggedIn} resource={resource} />
      </ResourcePanel>
    </section>
  );
}

ResourceInfo.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  resource: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired,
};

ResourceInfo = injectT(ResourceInfo); // eslint-disable-line

export default ResourceInfo;
