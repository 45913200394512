import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Grid from 'react-bootstrap/lib/Grid';
import { FormattedNumber } from 'react-intl';
import round from 'lodash/round';

import injectT from '../../../i18n/injectT';
import FavoriteButton from '../../../shared/favorite-button/FavoriteButtonContainer';
import { getPrice, getMaxPeriodText } from '../../../utils/resourceUtils';
import iconHome from '../../../assets/icons/home.svg';
import iconMapMarker from '../../../assets/icons/map-marker.svg';
import iconTicket from '../../../assets/icons/ticket.svg';
import iconUser from '../../../assets/icons/user-o.svg';
import iconClock from '../../../assets/icons/clock-o.svg';
import iconDoor from '../../../assets/icons/door-open.svg';
import iconKey from '../../../assets/icons/key.svg';
import iconPin from '../../../assets/icons/pin.svg';
import iconMap from '../../../assets/icons/map.svg';
import iconMobile from '../../../assets/icons/mobile.svg';
import { RESOURCE_AUTHENTICATION_GROUPING } from '../resource-auth-mapping';

function ResourceHeader({
  onBackClick,
  onMapClick,
  isLoggedIn,
  resource,
  showBackButton,
  showMap,
  unit,
  t,
}) {
  const formatDistance = (distance) => {
    if (!distance) {
      return '';
    }

    const km = distance / 1000;
    const formattedDistance = km < 10 ? round(km, 1) : round(km);
    return (
      <span>
        <FormattedNumber value={formattedDistance} />
        {' km'}
      </span>
    );
  };

  const accessIcons = {
    'mobile_app': iconMobile,
    'open_door': iconDoor,
    'physical_key': iconKey,
    'pincode': iconPin,
    'staff_member': iconUser,
  };

  const accessLabels = {
    'mobile_app': t('AccessMethod.mobileApp'),
    'open_door': t('AccessMethod.openDoor'),
    'physical_key': t('AccessMethod.physicalKey'),
    'pincode': t('AccessMethod.pincode'),
    'staff_member': t('AccessMethod.staffMember'),
  };

  const getAllowedLoginMethodsIcons = () => {
    const resourceAuthenticationLevel = resource.authentication;
    if (resourceAuthenticationLevel && resourceAuthenticationLevel !== 'none') {
      const resourceRequiredLoginMethod = RESOURCE_AUTHENTICATION_GROUPING[resourceAuthenticationLevel];
      const allowedLoginMethodIconsList = resourceRequiredLoginMethod && resourceRequiredLoginMethod.loginMethodIcons;
      const allowedLoginMethodNamesList = resourceRequiredLoginMethod && resourceRequiredLoginMethod.loginMethodNames;
      const iconslist = (
        <span>
          {t('ResourceCard.requiredLoginMethods', { loginMethodCount: allowedLoginMethodNamesList.length })}
          :&nbsp;
          <span>
            {allowedLoginMethodIconsList.map((icon, index) => (
              <img
                alt={allowedLoginMethodNamesList[index]}
                className="app-resourceCardInfoCell__icon"
                key={allowedLoginMethodNamesList[index]}
                src={icon}
                title={allowedLoginMethodNamesList[index]}
              />
            ))}
          </span>
        </span>
      );
      return iconslist;
    }
    return undefined;
  };

  const peopleCapacityText = t('ResourceCard.peopleCapacity', { people: resource.peopleCapacity });
  const maxPeriodText = getMaxPeriodText(t, resource);
  const priceText = getPrice(t, resource);
  const typeName = resource.type ? resource.type.name : '\u00A0';
  const distance = formatDistance(resource.distance);

  return (
    <section className="app-ResourceHeader">
      <Grid>
        <div className="app-ResourceHeader__content">
          {showBackButton && (
            <Button
              bsStyle="link"
              className="app-ResourceHeader__back-button"
              onClick={onBackClick}
            >
              {t('ResourceHeader.backButton')}
            </Button>
          )}
          <h1>{resource.name}</h1>
          <div className="app-ResourceHeader__info-wrapper">
            <div className="app-ResourceHeader__info">
              <img alt={t('ResourceHeader.purpose')} className="app-ResourceHeader__info-icon" src={iconHome} />
              <span className="app-ResourceHeader__info-label">{typeName}</span>
            </div>
            <div className="app-ResourceHeader__info">
              <img
                alt={t('ResourceHeader.capacity')}
                className="app-ResourceHeader__info-icon"
                src={iconUser}
              />
              <span className="app-ResourceHeader__info-label">{peopleCapacityText}</span>
            </div>
            {resource.maxPeriod && (
              <div className="app-ResourceHeader__info">
                <img alt={t('ResourceHeader.maxTime')} className="app-ResourceHeader__info-icon" src={iconClock} />
                <span className="app-ResourceHeader__info-label">{maxPeriodText}</span>
              </div>
            )}
            <div className="app-ResourceHeader__info">
              <img alt={t('ResourceHeader.price')} className="app-ResourceHeader__info-icon" src={iconTicket} />
              <span className="app-ResourceHeader__info-label">{priceText}</span>
            </div>
            {resource.area && (
              <div className="app-ResourceHeader__info">
                <span className="app-ResourceHeader__info-icon app-ResourceHeader__info-area-icon">
                  <span>m2</span>
                </span>
                <span className="app-ResourceHeader__info-label">
                  {resource.area}
                  <span>m</span>
                  <sup>2</sup>
                </span>
              </div>
            )
            }
            <div className="app-ResourceHeader__info" id="app-ResourceHeader__info--unit-name">
              <img
                alt={distance ? t('ResourceHeader.distanceAndPremise') : t('ResourceHeader.premise')}
                className="app-ResourceHeader__info-icon"
                src={iconMapMarker}
              />
              <span className="app-ResourceHeader__info-label">
                {distance}
                {distance && ', '}
                {unit.name}
              </span>
            </div>
            <div className="app-ResourceHeader__info">
              {getAllowedLoginMethodsIcons()}
            </div>
            {resource.accessMethods && resource.accessMethods.length > 0 && (
            <div className="app-ResourceHeader__info app-ResourceHeader__access-methods">
              <span className="app-ResourceHeader__info-label">
                {t('ResourceHeader.accessMethods')}
:
              </span>
                {resource.accessMethods.map(method => (
                  <span className="app-ResourceHeader__info-label access-method" key={method.id}>
                    <img
                      alt={accessLabels[method.id]}
                      src={accessIcons[method.id]}
                      title={accessLabels[method.id]}
                    />
                  </span>
                ))}
            </div>
            )}
            <div className="app-ResourceHeader__buttons">
              {!showMap && (
                <Button className="app-ResourceHeader__map-button" onClick={onMapClick}>
                  <img alt="" src={iconMap} />
                  <span>{t('ResourceHeader.mapButton')}</span>
                </Button>
              )}
              {showMap && (
                <Button className="app-ResourceHeader__map-button" onClick={onMapClick}>
                  <img alt="" src={iconMap} />
                  <span>{t('ResourceHeader.resourceButton')}</span>
                </Button>
              )}
              {isLoggedIn && <FavoriteButton resource={resource} />}
            </div>
          </div>

        </div>
      </Grid>
    </section>
  );
}

ResourceHeader.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onMapClick: PropTypes.func.isRequired,
  resource: PropTypes.object.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  showMap: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired,
};

ResourceHeader = injectT(ResourceHeader); // eslint-disable-line

export default ResourceHeader;
