import React from 'react';

export default () => (
  <svg
    enableBackground="new 0 0 512 512"
    height="24px"
    style={{
      display: 'block',
    }}
    version="1.1"
    viewBox="0 0 512 512"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
  >
    {/* eslint-disable-next-line max-len */}
    <path d="M256,0C114.8,0,0,114.8,0,256s114.8,256,256,256s256-114.8,256-256S397.2,0,256,0z M256,42.7   c118.1,0,213.3,95.3,213.3,213.3S374.1,469.3,256,469.3S42.7,374.1,42.7,256S137.9,42.7,256,42.7z M256,85.3   c-23.6,0-42.7,19.1-42.7,42.7s19.1,42.7,42.7,42.7s42.7-19.1,42.7-42.7S279.6,85.3,256,85.3z M132.7,171.3l-9.3,41.3l90,20V320   l-20.7,102.7l41.3,8L255.3,324l0.7-2l0.7,2L278,430.7l41.3-8L298.7,320v-87.3l90-20l-9.3-41.3L288,192h-64L132.7,171.3z" />
  </svg>
);
